import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { HiOutlineCheck, HiX } from 'react-icons/hi';
import { LuAlertCircle } from 'react-icons/lu';
import { MdOutlineCancel, MdOutlineCheckCircle } from 'react-icons/md';

import { Accordion } from '@components/Accordion';
import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { AddToCart, AddToFavourites, Button } from '@components/Button';
import { SocialShares } from '@components/Social';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useCustomer } from '@hooks/customer/useCustomer';
import {
  BaseProduct,
  isFullProduct,
  isSuggestFlavoursForm,
  Variant,
} from '@interfaces/Product';
import { isVariantAvailable } from '@lib/productHelpers';

import { BulkBuy } from '../BulkBuy';
import { FlavourDescriptions } from '../FlavourDescriptions';
import { NotifyStock } from '../NotifyStock';
import { Options } from '../Options';
import { ProductPricing } from '../ProductPrice';
import { Quantity } from '../Quantity';
import { StarRating } from '../StarRating';
import { SuggestAFlavour } from '../SuggestAFlavour';
import { USPComponent } from '../USP';

function ProductOverview({
  starRatings,
  description,
  ingredients,
}: {
  starRatings: { title: string; value: number }[];
  description: string;
  ingredients: { title: string; value: boolean }[];
}) {
  return (
    <>
      <p className="text-sm">{description}</p>
      <div className="grid grid-cols-2 text-sm">
        <ProductDetails starRatings={starRatings} />
        <DietaryInformation ingredients={ingredients} />
      </div>
    </>
  );
}

function ProductDetails({
  starRatings,
}: {
  starRatings: { title: string; value: number }[];
}) {
  return (
    <>
      <div>
        {starRatings.map((i, idx) => (
          <p
            key={`star${idx}`}
            className="my-1 flex items-center justify-start gap-1"
          >
            <StarRating rating={i.value} />
            {i.title}
          </p>
        ))}
      </div>
    </>
  );
}

function DietaryInformation({
  ingredients,
}: {
  ingredients: { title: string; value: boolean }[];
}) {
  return (
    <div>
      {ingredients.map((i, idx) => (
        <p
          key={`ingredient${idx}`}
          className="my-1 flex items-center justify-start"
        >
          <span className="icon mr-1">
            {i.value && (
              <HiOutlineCheck className="check text-green dark:text-white" />
            )}
            {!i.value && <HiX className="cross text-red dark:text-white" />}
          </span>
          {i.title}
        </p>
      ))}
    </div>
  );
}

function RewardsAndInstalments({
  loggedIn,
  price,
  title,
}: {
  loggedIn: boolean;
  price: number;
  title: string;
}) {
  return loggedIn ? (
    <>
      <h3 className="m-0 py-0 text-left text-base font-bold text-black dark:text-white">
        Point Rewards
      </h3>
      <p className="mb-4 text-sm">
        You're on your way to earning at least {Math.floor(price * 3)} points
        when you purchase {title}.{' '}
        <TextLink href="/rewards">Find out more</TextLink>.
      </p>
      <h3 className="m-0 py-0 text-left text-base font-bold text-black dark:text-white">
        Pay in 4 Payments
      </h3>
      <p className="mb-4 text-sm">
        For bigger orders over $100 AUD, choose to pay with AfterPay or PayPal
        Pay in 4. <TextLink href="/payments">Find out more</TextLink>.
      </p>
    </>
  ) : (
    <>
      <h3 className="m-0 py-0 text-left text-base font-bold text-black dark:text-white">
        Point Rewards
      </h3>
      <p className="mb-4 text-sm">
        <TextLink href="/login">Sign in</TextLink> or{' '}
        <TextLink href="/create-account">register an account</TextLink> to earn{' '}
        {Math.floor(price * 3)} points when you purchase {title}.
      </p>
      <p className="mb-4 text-sm">
        When you reach the Bronze/Silver tiers, you'll earn{' '}
        {Math.floor(price * 4)} points and {Math.floor(price * 5)} points on the
        Gold Tier. <TextLink href="/rewards">Find out more</TextLink>.
      </p>
      <h3 className="m-0 py-0 text-left text-base font-bold text-black dark:text-white">
        Pay in 4 Payments
      </h3>
      <p className="mb-4 text-sm">
        For bigger orders over $100 AUD, choose to pay with AfterPay or PayPal
        Pay in 4. <TextLink href="/payments">Find out more</TextLink>.
      </p>
    </>
  );
}

function Availability({
  product,
  inStock,
  partialStock,
}: {
  product: BaseProduct;
  inStock?: boolean;
  partialStock: boolean;
}) {
  return (
    <>
      {product.minPurchaseQty > 1 && (
        <p className="mb-4 text-sm">
          The minimum quantity purchasable of {product.name} is{' '}
          {product.minPurchaseQty}.
        </p>
      )}
      {product.maxPurchaseQty < 9999 && (
        <p className="mb-4 text-sm">
          The maximum quantity purchasable of {product.name} is{' '}
          {product.maxPurchaseQty}.
        </p>
      )}
      {partialStock && (
        <p className="mb-4 text-sm">
          Some options are not available to purchase of {product.name}.<br />
        </p>
      )}
      {isFullProduct(product) &&
      product.availability &&
      product.availability.length > 0 ? (
        <p className="mb-4 text-sm">{product.availability}</p>
      ) : (
        <p className="mb-4 text-sm">
          {inStock !== undefined &&
            inStock &&
            `In stock for same day dispatch when you order before 3pm ${
              new Date()
                .toLocaleString('en-AU', {
                  timeZone: 'Australia/Hobart',
                  timeZoneName: 'long',
                })
                .includes('Daylight')
                ? 'AEDT'
                : 'AEST'
            } (Monday to Friday)`}
          {inStock !== undefined &&
            !inStock &&
            'Currently out of stock. Check back soon or request a notification'}
        </p>
      )}
    </>
  );
}

export function ProductInformation({
  product,
  inStock,
  stockLevels,
  ...props
}: {
  product: BaseProduct;
  inStock?: boolean;
  stockLevels: Record<number, boolean> | undefined;
} & Record<string, any>) {
  const [quantity, setQuantity] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState<
    Variant | null | undefined
  >();

  const { customer } = useCustomer();

  const router = useRouter();
  const watchOptionChanges = props.rhfMethods.watch();

  const isDigital = product.sku.includes('BN-EBOOK');

  // Have to check that variants are available, based on banter etc
  const availableVariants = product.variants.filter((v) =>
    isVariantAvailable(v, customer ? customer.customerGroupId : undefined)
  );

  const descriptionsAvailable =
    availableVariants.filter((v) => v.flavourDescription).length > 0;

  // Work out if global or partial or no stock
  let partialStock = false;
  if (stockLevels) {
    partialStock = Object.entries(stockLevels).reduce((acc, [key, value]) => {
      if (!!availableVariants.find((v) => v.variantId === parseInt(key))) {
        return acc || !value;
      }
      return acc;
    }, false);
  }

  // ensure quantity resets to 1 when the product changes
  useEffect(() => setQuantity(1), [product.entityId]);

  // When a valid variant has been selected, set it and enable add to cart
  useEffect(() => {
    let mounted = true;
    const selectedOptions = props.rhfMethods.getValues();

    // when options changed, work out which variant that is
    const v = product.variants.filter(
      (pv) =>
        pv.options.length > 0 && // this is for dodgy data (when variants removed, usually affects staging)
        pv.options.reduce(
          (acc, cur) => acc && selectedOptions[cur.optionId] == cur.valueId,
          true as boolean
        )
    );

    if (v.length === 1 && mounted) {
      setSelectedVariant(v[0]); // set the variant as selected (for adding to cart)
    } else {
      setSelectedVariant(null);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [product.variants, product.options, watchOptionChanges, props.rhfMethods]);

  const renderAvailabilityIcon = () => {
    if (product.minPurchaseQty > 1 || product.maxPurchaseQty < 9999) {
      return <LuAlertCircle color="hsl(33, 80%, 52%)" />;
    }

    if (inStock && partialStock) {
      return <LuAlertCircle color="hsl(33, 80%, 52%)" />;
    }

    if (inStock && !partialStock) {
      return (
        <MdOutlineCheckCircle className="fill-green dark:fill-green-light" />
      );
    }

    // checking for false because inStock satisfies !inStock when undefined and defaults to red.
    if (inStock === false) {
      return <MdOutlineCancel color="hsl(0, 100%, 50%)" />;
    }

    return null;
  };

  const items = [] as Array<{
    title: string | React.ReactNode;
    content: React.ReactNode;
  }>;

  if (isFullProduct(product)) {
    const hasLimits =
      product.minPurchaseQty > 1 || product.maxPurchaseQty < 9999;

    items.push({
      title: 'Product Overview',
      content: (
        <ProductOverview
          starRatings={product.starRatings}
          description={product.shortDescription}
          ingredients={product.toggles}
        />
      ),
    });

    items.push({
      title: 'Rewards & Easy Instalments',
      content: (
        <RewardsAndInstalments
          loggedIn={customer ? true : false}
          price={product.pricing.priceRange.min.value}
          title={product.shortName ?? product.name}
        />
      ),
    });

    items.push({
      title: (
        <span className="flex items-center gap-2">
          Availability {hasLimits ? '& Limits' : ''} {renderAvailabilityIcon()}
        </span>
      ),
      content: (
        <Availability
          product={product}
          inStock={inStock}
          partialStock={partialStock}
        />
      ),
    });

    if (
      product.pricing.bulkPricing.length > 0 ||
      (selectedVariant && selectedVariant.pricing.bulkPricing.length > 0)
    ) {
      items.push({
        title: (
          <span className="flex items-center gap-2">
            Multibuy Discounts
            <MdOutlineCheckCircle className="fill-green dark:fill-green-light" />
          </span>
        ),
        content: (
          <BulkBuy
            bulkPricing={product.pricing.bulkPricing}
            variants={product.variants}
            servingSize={product.servingSize}
            packageDescription={product.packageDescription}
            maxPurchaseQty={product.maxPurchaseQty}
            hasFlavours={product.options.some(
              (o) =>
                o.displayName.toLowerCase().includes('flavour') &&
                o.values.length > 1
            )}
            title={product.shortName ?? product.name}
          />
        ),
      });
    }
  }

  return (
    <section>
      {isFullProduct(product) && product.usp && product.usp.length > 0 && (
        <USPComponent usps={product.usp} />
      )}

      {isFullProduct(product) && (
        <BulkErrorBoundary location="Product Information Accordion">
          <Accordion
            items={items}
            defaultOpen={true}
            openIndexes={[items.length - 1]}
            wrapperClass="[&>*]:border-grey-mid [&>*]:border-solid [&>*]:border-0 [&>*]:border-b"
            itemClass="font-bold pt-5 pb-1 px-0 clickable"
          />
        </BulkErrorBoundary>
      )}

      <div className="pt-5">
        <ProductPricing
          pricing={selectedVariant ? selectedVariant.pricing : product.pricing}
        />
      </div>

      <div>
        <div className="flex flex-col sm:flex-row sm:items-center sm:gap-4">
          <Options
            options={product.options}
            variants={availableVariants}
            stockLevels={stockLevels}
          />

          {descriptionsAvailable && (
            <FlavourDescriptions variants={availableVariants} />
          )}
        </div>
        {isFullProduct(product) &&
          product.suggestFlavours &&
          isSuggestFlavoursForm(product.suggestFlavours) && (
            <SuggestAFlavour
              form={product.suggestFlavours}
              productName={product.name}
              productId={product.entityId}
            />
          )}
        <NotifyStock
          inStock={inStock}
          defaultVariantId={product.defaultVariantId}
          variants={product.variants}
          name={product.name}
        />

        <div className="flex flex-col items-start gap-4 tiny:flex-row tiny:items-end">
          <Quantity
            inStock={inStock}
            quantity={quantity}
            setQuantity={setQuantity}
            min={product.minPurchaseQty}
            max={
              !!product.maxPurchaseQty && product.maxPurchaseQty > 0
                ? product.maxPurchaseQty
                : 9999
            }
          />

          <div className="flex gap-4">
            {/* border is added to match the height of quantity */}
            {inStock && !isDigital && (
              <div className="border border-solid border-orange">
                <AddToCart
                  queryID={router && router.query ? router.query.queryID : ''}
                  productId={product.entityId}
                  variantId={selectedVariant?.variantId || 0}
                  disabled={
                    (selectedVariant === null &&
                      !!product.options &&
                      product.options.length > 0) ||
                    quantity === 0
                  }
                  quantity={quantity}
                  triggerCartModal={props.triggerCartModal}
                />
              </div>
            )}
            {inStock !== undefined && (
              <div className="border border-solid border-transparent">
                <AddToFavourites
                  sku={product.sku}
                  productId={product.entityId}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <hr className="my-4 text-grey-mid" />

      <div className="flex justify-between">
        <SocialShares
          type={'product'}
          shareSlug={product.slug}
          titleText={product.name}
          imageSource={isFullProduct(product) ? product.defaultImgUrl : ''}
        />
        <Button
          href={props.productDetailsLink ?? '#Product-Description'}
          buttonStyle="text"
        >
          Full product details
        </Button>
      </div>
    </section>
  );
}
